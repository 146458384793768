import Vue from 'vue'
import VueRouter from 'vue-router'
import inicio from '@/components/recepcionCC.vue'
import adquisiciones from '../components/adquisiciones.vue'
import login from '../components/login.vue'
import informe from '../components/buscarInforme.vue'
import buscarRecepcion from '../components/buscarRecepcion.vue'
import cargarCamion from '../components/cargarCamionTransp.vue'
import registrarRecepcion from '../components/registrarRecepcion.vue'
import registrarInterno from '../components/registrarInterno.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/recepcionCC',
        name: 'pruebaInicio',
        component: inicio,
        meta: { requiresAuth: true, allowedRoles: ['TI', 'Adquisiciones', 'Recepción'] }
    },
    {
        path: '/vistaAdq',
        name: 'vistaAdq',
        component: adquisiciones,
        meta: { requiresAuth: true, allowedRoles: ['TI'] }
    },
    {
        path: '/',
        name: 'login',
        component: login,
    },
    {
        path: '/buscarInfo',
        name: 'buscarInfo',
        component: informe,
        meta: { requiresAuth: true, allowedRoles: ['TI', 'Adquisiciones', 'Recepción'] }
    },
    {
        path: '/BuscarRecep',
        name: 'BuscarRecep',
        component: buscarRecepcion,
        meta: { requiresAuth: true, allowedRoles: ['Santiago', 'TI', 'Adquisiciones', 'Recepción'] }
    },
    {
        path: '/cargarCam',
        name: 'cargarCam',
        component: cargarCamion,
        meta: { requiresAuth: true, allowedRoles: ['Santiago', 'TI'] }
    },
    {
        path: '/registrarRec',
        name: 'registrarRec',
        component: registrarRecepcion,
        meta: { requiresAuth: true, allowedRoles: ['Santiago', 'TI'] }
    },
    {
        path: '/registrarInterno',
        name: 'registrarInterno',
        component: registrarInterno,
        meta: { requiresAuth: true, allowedRoles: ['Adquisiciones', 'TI'] }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// Verificación de roles y autenticación
router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const userType = store.getters.tipo; // Obtener el tipo del usuario desde tu store

    if (requiresAuth && !userType) {
        next({ name: 'login' });
    } else if (requiresAuth && !to.meta.allowedRoles.includes(userType)) {
        next({ name: 'login' }); // O redirigir a otra página si no tiene permiso
    } else {
        next(); // Continuar si tiene acceso
    }
});

export default router;

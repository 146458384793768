<template>
    <div class="main-container">
        <nav class="navbar d-print-none navbar-light d-flex justify-content-center mb-4"
            style="background-color: #003A8C;">
            <div class="container">
                <ul class="navbar-nav">
                    <li class="nav-item active">
                        <div>
                            <b-dropdown id="dropdown-offset" offset="25" text="Opciones" class="m-2">
                                <!-- Visible solo para Santiago y TI -->
                                <b-dropdown-item v-if="userTipo === 'Santiago' || userTipo === 'TI'"
                                    href="/registrarRec">
                                    Registrar Recepción
                                </b-dropdown-item>

                                <!-- Visible solo para Santiago y TI -->
                                <b-dropdown-item v-if="userTipo === 'Santiago' || userTipo === 'TI'" href="/cargarCam">
                                    Cargar Camión Transportes
                                </b-dropdown-item>

                                <!-- Visible solo para Santiago y TI -->
                                <!--<b-dropdown-item v-if="userTipo === 'Adquisiciones' || userTipo === 'TI'"
                                    href="/registrarInterno">
                                    Reg. Recepción Interna
                                </b-dropdown-item>-->

                                <!-- Visible solo para TI -->
                                <b-dropdown-item
                                    v-if="userTipo === 'TI' || userTipo === 'Recepción' || userTipo === 'Adquisiciones'"
                                    href="/buscarInfo">
                                    Informe Traslados
                                </b-dropdown-item>

                                <!-- Visible solo para Santiago y TI -->
                                <b-dropdown-item
                                    v-if="userTipo === 'Santiago' || userTipo === 'TI' || userTipo === 'Recepción' || userTipo === 'Adquisiciones'"
                                    href="/buscarRecep">
                                    Buscar Guía Recepción
                                </b-dropdown-item>

                                <!-- Visible solo para TI -->
                                <b-dropdown-item
                                    v-if="userTipo === 'TI' || userTipo === 'Recepción' || userTipo === 'Adquisiciones'"
                                    href="/recepcionCC">
                                    Recepción Comercial
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </li>
                </ul>

                <div class="d-flex justify-content-center" id="logo">
                    <img class="img-fluid" src="https://seguimiento.cmimbral.cl/assets/img/logoSeguimiento.png"
                        width="300">
                </div>

                <div class="d-flex">
                    <span class="navbar-text text-white me-3">Usuario: {{ user?.nombre || 'Invitado' }}</span>
                    <button class="btn btn-danger ml-auto" @click="logout">
                        <b-icon icon="power" aria-hidden="true"></b-icon> Cerrar sesión
                    </button>
                </div>
            </div>
        </nav>
        <div style="background:#e7e7e7">
            <div class="d-print-none container-fluid col-md-7 shadow"
                style="background: white; padding: 20px; margin-top:50px; margin-bottom: 50px; border-radius: 20px;">
                <div class="row">
                    <div class="card-header bg-primary text-white">
                        <h2 class="text-center"><strong>Proveedor</strong></h2>
                    </div>
                    <div class="form-group col-md-12">
                        <label>Proveedor con mercadería disponible</label>
                        <select v-model="proveedorSeleccionado" class="form-control" @change="cargarAdquisiciones">
                            <option disabled value="">Seleccione un proveedor</option>
                            <option v-for="(proveedor, index) in proveedores" :key="index" :value="proveedor.id">
                                {{ proveedor.nombre }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <!-- Sección de tablas y detalles de transporte -->
        <div style="background:#e7e7e7">
            <div class="d-print-none container-fluid col-md-11 shadow"
                style="background: white; padding: 20px; margin-top:50px; border-radius: 20px;">
                <div class="row">
                    <!-- Tabla de pendientes -->
                    <div class="col-md-6 mb-4">
                        <div class="card">
                            <div class="card-header bg-primary text-white">
                                <h3 class="text-center"><strong>Adquisiciones Pendientes</strong></h3>
                            </div>
                            <div class="card-body">
                                <div class="table-responsive table-responsive-lg">
                                    <table class="table table-striped">
                                        <thead class="bg-primary text-white">
                                            <tr>
                                                <th class="text-center">N° Folio</th>
                                                <th class="text-center">Orden</th>
                                                <th class="text-center">Bultos o Caja</th>
                                                <th class="text-center">Pallet</th>
                                                <th class="text-center">Acción</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in pendientesFiltrados" :key="item.id">
                                                <td>{{ item.folio_prov }}</td>
                                                <td>{{ item.orden }}</td>
                                                <td>{{ item.bultos }}</td>
                                                <td>{{ item.pallet }}</td>
                                                <td>
                                                    <button type="button" class="btn btn-success btn-sm"
                                                        @click="asignarSoporte(item.id)">
                                                        <i class="fas fa-plus-circle"></i> Asignar
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Tabla de asignados -->
                    <div class="col-md-6 mb-4">
                        <div class="card">
                            <div class="card-header bg-primary text-white">
                                <h3 class="text-center"><strong>Registrar traslado de mercadería</strong></h3>
                            </div>
                            <div class="card-body">
                                <div class="table-responsive table-responsive-lg">
                                    <table class="table table-striped">
                                        <thead class="bg-primary text-white">
                                            <tr>
                                                <th class="text-center">N° Guía</th>
                                                <th class="text-center">Orden</th>
                                                <th class="text-center">Bultos o caja</th>
                                                <th class="text-center">Pallet</th>
                                                <th class="text-center">Acción</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in asignados" :key="item.id">
                                                <td>{{ item.folio_prov }}</td>
                                                <td>{{ item.orden }}</td>
                                                <td>{{ item.bultos }}</td>
                                                <td>{{ item.pallet }}</td>
                                                <td>
                                                    <button type="button" class="btn btn-danger btn-sm"
                                                        @click="quitarAsignacion(item.id)">
                                                        <i class="fas fa-trash-alt"></i> Quitar
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <br>
                                <div class="form-group col-md-12">
                                    <div class="row">
                                        <div class="card-header bg-primary text-white">
                                            <h4 class="text-center"><strong>Datos de transporte</strong></h4>
                                        </div>
                                        <div class="form-group col-md-4 input-group">
                                            <label for="rutChofer" class="mb-2">RUT Chofer</label>
                                            <input type="text" id="rutChofer" v-model="rutChofer" class="form-control"
                                                placeholder="Ingrese RUT" @input="formatRut" required>
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary" type="button"
                                                    @click="buscarChofer">
                                                    <i class="fas fa-search"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-8">
                                            <label for="nombreChofer">Nombre</label>
                                            <input type="text" id="nombreChofer" v-model="nombreChofer"
                                                class="form-control" placeholder="Ingrese nombre del chofer">
                                        </div>
                                        <div class="form-group col-md-4">
                                            <label for="patente">Patente</label>
                                            <input type="text" id="patente" v-model="patente" class="form-control"
                                                placeholder="Ingrese patente">
                                        </div>
                                    </div>
                                </div>
                                <br>
                                <div class="form-group col-md-12">
                                    <div class="row">
                                        <div class="form-group col-md-12">
                                            <label>Observación (Opcional)</label>
                                            <textarea id="observacion" class="form-control"
                                                placeholder="Dejar una observación" v-model="observacion"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-12">
                                    <div class="row" role="group">
                                        <div class="form-group col-md-2">
                                            <button @click="registrarTraslado" type="button" class="btn btn-primary">
                                                Registrar traslado
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import { mapState } from "vuex";
import moment from 'moment';

export default {
    data() {
        return {
            user: '',
            rut: '',
            observacion: '',
            rutChofer: '',
            nombreChofer: '',
            patente: '',
            proveedores: [],  // Lista de proveedores con mercadería pendiente
            proveedorSeleccionado: '',  // Proveedor seleccionado en el dropdown
            pendientes: [],  // Lista de guías o facturas pendientes del proveedor seleccionado
            asignados: [],    // Lista de guías o facturas asignadas localmente para traslado
            asignadosGlobal: [] // Lista global de todos los elementos asignados para evitar duplicados
        };
    },
    computed: {
        // Filtrar pendientes para no mostrar los ya asignados
        pendientesFiltrados() {
            return this.pendientes.filter(pendiente => !this.asignadosGlobal.some(asignado => asignado.id === pendiente.id));
        },
        userTipo() {
            return this.$store.getters.tipo;
        },
    },
    methods: {
        async logout() {
            try {
                await axios.post('https://apisantiago.cmimbral.cl/logout', {}, {
                });
                this.$router.push('/');
            } catch (error) {
                console.error("Error al cerrar sesión", error.message);
                Swal.fire({
                    icon: "error",
                    title: "Error al cerrar sesión",
                    text: "Ocurrió un error al intentar cerrar sesión.",
                });
            }
        },

        formatRut() {
            let rutLimpio = this.rutChofer.replace(/[^0-9Kk]/g, '');
            if (rutLimpio.length > 1) {
                if (rutLimpio.slice(-1).toUpperCase() === 'K') {
                    rutLimpio = rutLimpio.slice(0, -1) + 'K';
                }
                const cuerpo = rutLimpio.slice(0, -1);
                const digitoVerificador = rutLimpio.slice(-1);
                this.rutChofer = `${cuerpo}-${digitoVerificador}`;
            } else {
                this.rutChofer = rutLimpio;
            }
        },

        async cargarProveedores() {
            try {
                const response = await axios.get('https://apisantiago.cmimbral.cl/proveedoresPendientes');
                this.proveedores = response.data;

                if (this.proveedores.length > 0) {
                    this.proveedorSeleccionado = this.proveedores[0].id;
                    this.cargarAdquisiciones();
                }
            } catch (error) {
                console.error("Error al cargar proveedores", error.message);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Ocurrió un error al cargar la lista de proveedores.",
                });
            }
        },

        async cargarAdquisiciones() {
            if (!this.proveedorSeleccionado) {
                return;
            }
            try {
                const response = await axios.get(`https://apisantiago.cmimbral.cl/adquisicionesPendientes/${this.proveedorSeleccionado}`);
                this.pendientes = response.data.adquisiciones;

                // Verificar si algún elemento pendiente ya está asignado globalmente y actualizar su estado
                this.pendientes.forEach(pendiente => {
                    const asignado = this.asignadosGlobal.some(asignado => asignado.id === pendiente.id);
                    pendiente.asignado = asignado;
                });
            } catch (error) {
                console.error("Error al cargar adquisiciones", error.message);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Ocurrió un error al cargar la lista de adquisiciones.",
                });
            }
        },

        asignarSoporte(id) {
            const item = this.pendientes.find(pendiente => pendiente.id === id);
            if (item && !item.asignado) {
                item.asignado = true;
                this.asignados.push(item);
                this.asignadosGlobal.push(item);
            }
        },

        quitarAsignacion(id) {
            const index = this.asignados.findIndex(asignado => asignado.id === id);
            if (index !== -1) {
                const item = this.asignados.splice(index, 1)[0];
                item.asignado = false;
                const globalIndex = this.asignadosGlobal.findIndex(a => a.id === item.id);
                if (globalIndex !== -1) {
                    this.asignadosGlobal.splice(globalIndex, 1);
                }
            }
        },

        async registrarTraslado() {
            // Validar que el RUT no esté vacío
            if (!this.rutChofer) {
                Swal.fire({
                    icon: "warning",
                    title: "RUT requerido",
                    text: "Por favor, ingrese el RUT del chofer.",
                });
                return; // Salir del método si el RUT está vacío
            }

            const idsAsignados = this.asignados.map(item => item.id);

            try {
                //Depuración de datos enviados al servidor
                console.log('Datos enviados al servidor:', {
                    chofer: {
                        nombre: this.nombreChofer,
                        rut: this.rutChofer,
                        patente: this.patente,
                        observacion: this.observacion
                    },
                    items: idsAsignados,
                    fechaTraslado: new Date().toISOString(),
                });

                // Registrar el traslado en el servidor local
                const response = await axios.post('https://apisantiago.cmimbral.cl/registrarTraslado', {
                    chofer: {
                        nombre: this.nombreChofer,
                        rut: this.rutChofer,
                        patente: this.patente,
                        observacion: this.observacion
                    },
                    items: idsAsignados,
                    fechaTraslado: new Date().toISOString(),
                });

                if (response.status === 200) {
                    Swal.fire({
                        icon: "success",
                        title: "Traslado registrado",
                        text: "El traslado ha sido registrado exitosamente.",
                    });

                    // Resetear los campos y recargar adquisiciones pendientes
                    this.asignados = [];
                    this.nombreChofer = '';
                    this.rutChofer = '';
                    this.patente = '';
                    this.observacion = '';
                    await this.cargarAdquisiciones();
                } else {
                    console.error("Respuesta no exitosa del servidor", response);
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: `Error al registrar el traslado: ${response.status} - ${response.statusText}`,
                    });
                }
            } catch (error) {
                // Extraer más detalles del error
                console.error("Error al registrar traslado:", error.message);
                console.error("Detalles del error:", error.response ? error.response.data : "No hay respuesta del servidor");

                let errorMessage = "Ocurrió un error al intentar registrar el traslado.";
                if (error.response) {
                    // Si el servidor respondió con un error (status 500 o similar)
                    errorMessage = `Error del servidor: ${error.response.status} - ${error.response.statusText}`;
                    if (error.response.data && error.response.data.message) {
                        errorMessage += ` - ${error.response.data.message}`;
                    }
                }

                console.log('Error completo:', error);
                if (error.response) {
                    console.log('Detalles del error en el servidor:', error.response.data);
                }

                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: errorMessage,
                });
            }
        },

        async buscarChofer() {
            try {
                const response = await axios.get(`https://apisantiago.cmimbral.cl/buscarChofer/${this.rutChofer}`);

                if (response.status === 200) {
                    this.nombreChofer = response.data.chofer;
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Chofer no encontrado.',
                    });
                }
            } catch (error) {
                console.error('Error al buscar el chofer:', error.message);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Ocurrió un error al buscar el chofer.',
                });
            }
        },

        async obtenerUsuario() {
            try {
                const response = await axios.get('https://apisantiago.cmimbral.cl/usuario', {
                    params: {
                        nombre: this.$store.getters.user,
                        correo: this.$store.getters.correo,
                        tipo: this.$store.getters.tipo,
                        rut: this.$store.getters.rut,
                        id: this.$store.getters.id,
                    }
                });
                this.user = response.data;
                // Si deseas actualizar el store con los datos recibidos:
                this.$store.dispatch('login', response.data);
            } catch (error) {
                console.error("Error al obtener usuario", error.response ? error.response.data : error.message);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Ocurrió un error al intentar obtener los datos del usuario.",
                });
            }
        },
    },
    created() {
        this.cargarProveedores();
        this.obtenerUsuario();
    }
}
</script>

<style scoped>
.badge-success {
    background-color: #28a745;
    padding: 0.5em 1em;
    border-radius: 0.25em;
    color: white;
}

/* Otros estilos */
.table th,
.table td {
    vertical-align: middle;
    text-align: center;
}

.card {
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.card-header {
    background: #0d6efd;
    /* Azul claro */
    color: white;
    text-align: center;
}

/* Personalizar estilo para la navbar */
.custom-navbar-color {
    background-color: #013b8c;
}

/* Ajustes adicionales de estilos */
.table th,
.table td {
    vertical-align: middle;
    text-align: center;
}

.card {
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.card-header {
    background: #0d6efd;
    /* Azul claro */
    color: white;
    text-align: center;
}

/* Estilo para el input del RUT y el botón */
#rutChofer {
    width: 150px;
    /* Ancho reducido */
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

#rutChofer+.input-group-append .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 5px 10px;
    /* Padding reducido para el botón */
}
</style>

<template>
    <div style="background:#e7e7e7; min-height: 100vh;">
        <nav class="navbar d-print-none navbar-light d-flex justify-content-center mb-4"
            style="background-color: #003A8C;">
            <div class="container">
                <ul class="navbar-nav">
                    <li class="nav-item active">
                        <div>
                            <b-dropdown id="dropdown-offset" offset="25" text="Opciones" class="m-2">
                                <!-- Visible solo para Santiago y TI -->
                                <b-dropdown-item v-if="userTipo === 'Santiago' || userTipo === 'TI'"
                                    href="/registrarRec">
                                    Registrar Recepción
                                </b-dropdown-item>

                                <!-- Visible solo para Santiago y TI -->
                                <b-dropdown-item v-if="userTipo === 'Santiago' || userTipo === 'TI'" href="/cargarCam">
                                    Cargar Camión Transportes
                                </b-dropdown-item>

                                <!-- Visible solo para Santiago y TI -->
                                <!--<b-dropdown-item v-if="userTipo === 'Adquisiciones' || userTipo === 'TI'"
                                    href="/registrarInterno">
                                    Reg. Recepción Interna
                                </b-dropdown-item>-->

                                <!-- Visible solo para TI -->
                                <b-dropdown-item
                                    v-if="userTipo === 'TI' || userTipo === 'Recepción' || userTipo === 'Adquisiciones'"
                                    href="/buscarInfo">
                                    Informe Traslados
                                </b-dropdown-item>

                                <!-- Visible solo para Santiago y TI -->
                                <b-dropdown-item
                                    v-if="userTipo === 'Santiago' || userTipo === 'TI' || userTipo === 'Recepción' || userTipo === 'Adquisiciones'"
                                    href="/buscarRecep">
                                    Buscar Guía Recepción
                                </b-dropdown-item>

                                <!-- Visible solo para TI -->
                                <b-dropdown-item
                                    v-if="userTipo === 'TI' || userTipo === 'Recepción' || userTipo === 'Adquisiciones'"
                                    href="/recepcionCC">
                                    Recepción Comercial
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </li>
                </ul>

                <div class="d-flex justify-content-center" id="logo">
                    <img class="img-fluid" src="https://seguimiento.cmimbral.cl/assets/img/logoSeguimiento.png"
                        width="300">
                </div>

                <div class="d-flex">
                    <span class="navbar-text text-white me-3">Usuario: {{ user?.nombre || 'Invitado' }}</span>
                    <button class="btn btn-danger ml-auto" @click="logout">
                        <b-icon icon="power" aria-hidden="true"></b-icon> Cerrar sesión
                    </button>
                </div>
            </div>
        </nav>
        <div class="container mt-3">
            <div class="card">
                <div class="card-header bg-primary text-white">
                    <h2 class="text-center"><strong>Recepción de mercadería comercial</strong></h2>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead class="bg-primary text-white">
                                <tr>
                                    <th scope="col">N° Traslado</th>
                                    <th scope="col">n° Guía</th>
                                    <th scope="col">Proveedor</th>
                                    <th scope="col">Rut</th>
                                    <th scope="col">Foto</th>
                                    <th scope="col">Observación</th>
                                    <th scope="col">Fecha de Inicio</th>
                                    <th scope="col">Fecha de Término</th>
                                    <th scope="col">Revisión</th>
                                    <th scope="col">Responsable</th>
                                    <th scope="col">Estado</th>
                                    <th scope="col">Histórico</th> <!-- Nuevo botón para el chat -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(dato, index) in datosFiltrados" :key="index">
                                    <td>{{ dato.traslado }}</td>
                                    <td>{{ dato.folio_prov }}</td>
                                    <td>{{ dato.proveedor }}</td>
                                    <td>{{ dato.rut }}</td>
                                    <td>
                                        <!-- Mostrar el botón "Ver" si existe una foto -->
                                        <div v-if="dato.foto_path">
                                            <button @click="viewPhoto(dato.foto_path)"
                                                class="btn btn-secondary btn-uniform">
                                                <i class="fas fa-eye"></i> Ver
                                            </button>
                                        </div>
                                        <!-- Mostrar el botón "Subir Foto" si no existe una foto -->
                                        <div v-else>
                                            <button @click="openModal(dato)" class="btn btn-success btn-uniform">
                                                <i class="fas fa-upload"></i> Subir Foto
                                            </button>
                                        </div>
                                    </td>
                                    <td>
                                        <button v-if="!dato.observacion" class="btn btn-secondary btn-sm btn-uniform2"
                                            @click="agregarObservacion(dato)">Agregar</button>
                                        <button v-else class="btn btn-info btn-sm btn-uniform2"
                                            @click="verObservacion(dato)">Ver</button>
                                    </td>
                                    <td>
                                        <button v-if="!dato.fecha_inicio || dato.fecha_inicio === 'Invalid date'"
                                            class="btn btn-success btn-sm btn-uniform"
                                            @click="iniciarRevision(dato)">Iniciar
                                            Revisión</button>
                                        <span v-else>{{ dato.fecha_inicio }}</span>
                                    </td>
                                    <td>
                                        <button v-if="!dato.fecha_fin || dato.fecha_fin === 'Invalid date'"
                                            class="btn btn-success btn-sm btn-uniform"
                                            @click="terminarRevision(dato)">Terminar
                                            Revisión</button>
                                        <span v-else>{{ dato.fecha_fin }}</span>
                                    </td>
                                    <td>{{ dato.revision }}</td>
                                    <td>{{ dato.responsable }}</td>
                                    <td>
                                        <span v-if="dato.estado === 0">Sin iniciar</span>
                                        <span v-else-if="dato.estado === 1">En proceso</span>
                                        <span v-else>Completado</span>
                                    </td>
                                    <td>
                                        <button class="btn btn-info btn-uniform" @click="abrirChat(dato)">
                                            <i :class="dato.nuevosMensajes ? 'fas fa-envelope' : 'fas fa-comments'"></i>
                                            {{ dato.nuevosMensajes ? 'Nuevo' : 'Histórico' }}
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal para ver la foto -->
        <div v-if="photoUrl" class="modal" tabindex="-1" role="dialog" style="display:block;">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Foto</h5>
                        <button type="button" class="close" @click="closeModal">
                            <span>&times;</span>
                        </button>
                    </div>
                    <div class="modal-body text-center">
                        <img :src="photoUrl" alt="Foto" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal para el chat -->
        <div v-if="chatVisible" class="modal" tabindex="-1" role="dialog" style="display:block;">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Histórico</h5>
                        <button type="button" class="close" @click="cerrarChat">
                            <span>&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Fecha</th>
                                    <th>Usuario</th>
                                    <th>Mensaje</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(mensaje, index) in chatMensajes" :key="index">
                                    <td>{{ moment(mensaje.fecha_registro).format('DD-MM-YYYY HH:mm') }}</td>
                                    <td>{{ mensaje.usuario }}</td>
                                    <td>{{ mensaje.mensaje }}</td>
                                </tr>
                            </tbody>
                        </table>

                        <!-- Input para enviar mensaje -->
                        <div class="input-group mt-3">
                            <input v-model="nuevoMensaje" type="text" class="form-control"
                                placeholder="Escribir mensaje...">
                            <div class="input-group-append">
                                <button class="btn btn-primary" @click="enviarMensaje">Enviar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal para subir la foto -->
        <div v-if="selectedDato" class="modal" tabindex="-1" role="dialog" style="display:block;">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Subir Foto</h5>
                        <button type="button" class="close" @click="closeUploadModal">
                            <span>&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <input type="file" ref="fileInput" @change="handleFileUpload($event, selectedDato)"
                            accept="image/*" class="form-control-file">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import { BIcon } from 'bootstrap-vue';
import moment from 'moment';
import { mapState } from "vuex";

export default {
    /*computed: {
        formattedDate() {
            return moment(this.fecha).format('YYYY-MM-DD HH:mm');
        },
        userTipo() {
            return this.$store.getters.tipo;
        },
        ...mapState({
            user: state => state.user
        })
    },*/
    components: {
        'b-icon': BIcon,
    },
    data() {
        return {
            isModalVisible: false,
            chatVisible: false,
            chatMensajes: [],
            nuevoMensaje: '',
            idRecepcionComercial: null,
            user: null,
            datos: [],
            filtroTipo: '',
            filtroRut: '',
            fechaInicio: '',
            fechaFin: '',
            selectedFile: null,
            idRecepcionComercial: null,
            photoUrl: '',
            selectedDato: null,
            viewImageUrl: null,
            isUploading: false,
        };
    },

    computed: {
        userTipo() {
            return this.$store.getters.tipo;
        },

        datosFiltrados() {
            let datosFiltrados = this.datos;
            return datosFiltrados.sort((a, b) => b.traslado - a.traslado);
        }
    },

    methods: {
        moment,
        async fetchData() {
            try {
                const response = await axios.get('https://apisantiago.cmimbral.cl/comercial');

                // Procesar los datos y obtener los mensajes nuevos para cada entrada
                this.datos = await Promise.all(response.data.formattedResults.map(async dato => {
                    const id = dato.id_recepcion_comercial || dato.traslado;
                    let nuevosMensajes = false;

                    try {
                        // Obtener mensajes del backend y verificar si hay mensajes no leídos
                        const mensajesResponse = await axios.get(`https://apisantiago.cmimbral.cl/historico/${id}`);
                        if (mensajesResponse.data.some(mensaje => !mensaje.leido)) {
                            nuevosMensajes = true; // Marcar si hay mensajes nuevos
                        }
                    } catch (error) {
                        console.error(`Error al obtener mensajes para id ${id}:`, error.message);
                    }

                    // Devolver el dato con la propiedad de nuevos mensajes
                    return {
                        ...dato,
                        id,
                        foto_path: dato.foto_path || null,
                        nuevosMensajes // Agregar la propiedad nuevosMensajes
                    };
                }));

            } catch (error) {
                console.error("Error al obtener los datos comerciales:", error.message);
                Swal.fire({
                    icon: "error",
                    title: "Error al obtener los datos",
                    text: "Ocurrió un error al intentar obtener los datos.",
                });
            }
        },

        openModal(dato) {
            this.selectedDato = dato;
            this.$nextTick(() => {
                if (this.$refs.fileInput) {
                    this.$refs.fileInput.click();
                } else {
                    console.error("Referencia de fileInput no encontrada");
                }
            });
        },

        handleFileUpload(event, dato) {
            const file = event.target.files[0];
            if (file) {
                dato.file = file;
                this.uploadFoto(dato);
            }
        },

        async uploadFoto(dato) {
            if (!dato.file) {
                Swal.fire('Error', 'Debe seleccionar una foto primero', 'error');
                return;
            }

            const formData = new FormData();
            formData.append('foto', dato.file);

            this.isUploading = true;

            try {
                // Asegúrate de que la URL y los headers estén bien formados
                const response = await axios.put(
                    `https://apisantiago.cmimbral.cl/recepcioncomercial/${dato.id_recepcion_comercial}/foto`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        }
                    }
                );

                // Muestra un mensaje de éxito
                Swal.fire('Foto subida', '', 'success');

                // Actualiza el campo foto con la URL devuelta por el servidor
                dato.foto_path = response.data.imageUrl;  // Asigna la nueva URL al campo foto_path

                // Actualiza la propiedad para mostrar el botón "Ver"
                dato.file = null; // Limpiamos el archivo seleccionado

                // Cierra el modal de subida de foto
                this.closeUploadModal();
            } catch (error) {
                // Maneja el error y muestra un mensaje apropiado
                Swal.fire('Error', 'No se pudo subir la foto', 'error');
            } finally {
                // Asegúrate de que el estado de carga se restablezca
                this.isUploading = false;
            }
        },

        closeModal() {
            this.photoUrl = '';
        },

        closeUploadModal() {
            this.selectedDato = null;
        },

        viewPhoto(foto_path) {
            this.photoUrl = `https://apisantiago.cmimbral.cl${foto_path}`;
        },

        async agregarObservacion(dato) {
            const { value: observacion } = await Swal.fire({
                title: 'Agregar Observación',
                input: 'textarea',
                inputLabel: 'Observación',
                inputPlaceholder: 'Escribe tu observación aquí...',
                inputAttributes: {
                    'aria-label': 'Escribe tu observación aquí'
                },
                showCancelButton: true
            });

            if (observacion) {
                try {
                    await axios.post('https://apisantiago.cmimbral.cl/registrarObservacionComercial', { id: dato.id_recepcion_comercial, observacion });

                    // Actualiza los datos en el frontend
                    dato.observacion = observacion;
                } catch (error) {
                    console.error("Error al guardar la observación:", error.message);
                    Swal.fire({
                        icon: "error",
                        title: "Error al guardar la observación",
                        text: "Ocurrió un error al intentar guardar la observación.",
                    });
                }
            }
        },

        async verObservacion(dato) {
            Swal.fire({
                title: 'Observación',
                text: dato.observacion,
                showCancelButton: false,
                showConfirmButton: true
            });
        },

        async iniciarRevision(dato) {
            const { value: responsable } = await Swal.fire({
                title: 'Iniciar Revisión',
                input: 'text',
                inputLabel: 'Responsable de Revisión',
                inputPlaceholder: 'Escribe el nombre del responsable de la revisión...',
                inputAttributes: {
                    'aria-label': 'Escribe el nombre del responsable de la revisión'
                },
                showCancelButton: true
            });

            if (responsable) {
                try {
                    const idRecepcion = dato.id_recepcion_comercial;
                    const fecha_ini = moment().format('YYYY-MM-DD HH:mm:ss');

                    await axios.post('https://apisantiago.cmimbral.cl/iniciarRevision', { id: idRecepcion, responsable, fecha_ini });

                    // Actualiza los datos en el frontend
                    dato.revision = responsable;
                    dato.fecha_inicio = moment(fecha_ini).format('DD-MM-YYYY HH:mm');
                    dato.estado = 1;
                } catch (error) {
                    console.error("Error al iniciar la revisión:", error.message);
                    Swal.fire({
                        icon: "error",
                        title: "Error al iniciar la revisión",
                        text: "Ocurrió un error al intentar iniciar la revisión.",
                    });
                }
            }
        },

        async terminarRevision(dato) {
            const { value: responsable } = await Swal.fire({
                title: 'Terminar Revisión',
                input: 'text',
                inputLabel: 'Responsable de Cargar Datos a SAP',
                inputPlaceholder: 'Escribe el nombre del responsable de cargar datos...',
                inputAttributes: {
                    'aria-label': 'Escribe el nombre del responsable de cargar datos'
                },
                showCancelButton: true
            });

            if (responsable) {
                try {
                    const fecha_fin = moment().format('YYYY-MM-DD HH:mm:ss'); // Formato correcto para la base de datos
                    const idRecepcion = dato.id_recepcion_comercial;

                    await axios.post('https://apisantiago.cmimbral.cl/terminarRevision', { id: idRecepcion, responsable, fecha_fin });

                    // Actualiza los datos en el frontend
                    dato.responsable = responsable;
                    dato.fecha_fin = moment(fecha_fin).format('DD-MM-YYYY HH:mm'); // Mostrar en formato DD-MM-YYYY HH:mm
                    dato.estado = 2;
                } catch (error) {
                    console.error("Error al terminar la revisión:", error.message);
                    Swal.fire({
                        icon: "error",
                        title: "Error al terminar la revisión",
                        text: "Ocurrió un error al intentar terminar la revisión.",
                    });
                }
            }
        },

        async logout() {
            try {
                await axios.post('https://apisantiago.cmimbral.cl/logout');
                window.location.href = '/';
            } catch (error) {
                console.error("Error al cerrar sesión", error.message);
                Swal.fire({
                    icon: "error",
                    title: "Error al cerrar sesión",
                    text: "Ocurrió un error al intentar cerrar la sesión.",
                });
            }
        },

        async obtenerUsuario() {
            try {
                const response = await axios.get('https://apisantiago.cmimbral.cl/usuario', {
                    params: {
                        nombre: this.$store.getters.user,
                        correo: this.$store.getters.correo,
                        tipo: this.$store.getters.tipo,
                        rut: this.$store.getters.rut,
                        id: this.$store.getters.id,
                    }
                });
                this.user = response.data;
                // Si deseas actualizar el store con los datos recibidos:
                this.$store.dispatch('login', response.data);
            } catch (error) {
                console.error("Error al obtener usuario", error.response ? error.response.data : error.message);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Ocurrió un error al intentar obtener los datos del usuario.",
                });
            }
        },

        // Enviar el campo leido al hacer la solicitud POST
        async abrirChat(dato) {
            this.chatVisible = true;
            this.idRecepcionComercial = dato.id;

            // Cargar mensajes del chat
            await this.obtenerMensajes(this.idRecepcionComercial);

            // Obtener el ID del usuario
            const usuarioId = Number(this.$store.getters.id);  // Asegúrate de que el usuarioId sea un número

            // Verificar si hay mensajes no leídos
            const noLeido = this.chatMensajes.some(mensaje => {
                let leidos;
                try {
                    if (typeof mensaje.leido === 'string') {
                        // Convertir el string en un array de números
                        leidos = mensaje.leido.replace(/[\[\]]/g, '').split(',').map(Number);
                    } else if (Array.isArray(mensaje.leido)) {
                        leidos = mensaje.leido; // Si ya es un array, usarlo directamente
                    } else {
                        leidos = []; // Inicializar como array vacío si es null u otro formato
                    }
                } catch (e) {
                    console.error("Error al parsear leidos:", e);
                    leidos = [];
                }

                return !leidos.includes(usuarioId);
            });

            // Si hay mensajes no leídos, marcarlos como leídos
            if (noLeido) {
                try {
                    for (const mensaje of this.chatMensajes) {
                        let leidos;
                        try {
                            if (typeof mensaje.leido === 'string') {
                                // Convertir el string en un array de números
                                leidos = mensaje.leido.replace(/[\[\]]/g, '').split(',').map(Number);
                            } else if (Array.isArray(mensaje.leido)) {
                                leidos = mensaje.leido;
                            } else {
                                leidos = [];
                            }
                        } catch (e) {
                            console.error("Error al parsear leidos:", e);
                            leidos = [];
                        }

                        if (!leidos.includes(usuarioId)) {
                            leidos.push(usuarioId); // Agrega el usuarioId al array

                            // Log para ver qué datos estamos enviando
                            console.log("Marcando mensaje como leído, ID mensaje:", mensaje.id);
                            console.log("Enviando leidos:", leidos);

                            // Enviar el campo `leido` actualizado al servidor
                            await axios.post(`https://apisantiago.cmimbral.cl/historico/marcarLeido/${mensaje.id}`, {
                                userId: usuarioId,
                                leido: JSON.stringify(leidos) // Convertir el array en JSON string
                            });
                        }
                    }
                    dato.nuevosMensajes = false; // Marcar que no hay mensajes nuevos
                } catch (error) {
                    // Log para capturar los errores
                    console.error("Error al marcar como leídos:", error.response ? error.response.data : error.message);
                }
            }
        },

        cerrarChat() {
            this.chatVisible = false;
        },

        async obtenerMensajes(idRecepcionComercial) {
            try {
                const response = await axios.get(`https://apisantiago.cmimbral.cl/historico/${idRecepcionComercial}`);
                this.chatMensajes = response.data;

                // Obtener el ID del usuario actual desde el store
                const usuarioId = Number(this.$store.getters.id);

                // Verificar si hay mensajes no leídos
                const hayMensajesNoLeidos = response.data.some(mensaje => {
                    let leidos = [];

                    try {
                        // Validar el campo 'leido' antes de intentar parsearlo
                        if (Array.isArray(mensaje.leido)) {
                            // Si es un array, úsalo directamente
                            leidos = mensaje.leido;
                        } else if (typeof mensaje.leido === 'string') {
                            // Si es una cadena, intenta parsearla como JSON
                            leidos = JSON.parse(mensaje.leido);

                            // Si `leidos` no es un array después del parseo, lo inicializamos vacío
                            if (!Array.isArray(leidos)) {
                                console.warn(`El campo 'leido' no es un array después del parseo: ${mensaje.leido}`);
                                leidos = [];
                            }
                        } else if (mensaje.leido === null || mensaje.leido === undefined) {
                            // Si es null o undefined, lo tratamos como un array vacío
                            leidos = [];
                        } else {
                            console.warn(`Formato inesperado para el campo 'leido':`, mensaje.leido);
                            leidos = [];
                        }

                        // Asegurarse de que el array esté compuesto por números
                        leidos = leidos.map(num => Number(num)).filter(num => !isNaN(num));

                    } catch (e) {
                        console.error("Error al parsear leidos:", e);
                        leidos = []; // En caso de error, usamos un array vacío
                    }

                    // Comprobamos si el usuario actual no está en el array de 'leidos'
                    return !leidos.includes(usuarioId);
                });

                // Actualizar el estado de nuevosMensajes en datosFiltrados
                const estadoMensajes = this.datosFiltrados.find(d => d.id === idRecepcionComercial);
                if (estadoMensajes) {
                    this.$set(estadoMensajes, 'nuevosMensajes', hayMensajesNoLeidos);
                }

            } catch (error) {
                console.error('Error al obtener los mensajes:', error.message);
            }
        },

        // Método para recorrer todas las entradas de la tabla y verificar mensajes
        async verificarMensajesNoLeidos() {
            for (let dato of this.datosFiltrados) {
                await this.obtenerMensajes(dato.id);
            }
        },

        async enviarMensaje() {
            if (this.nuevoMensaje.trim() !== '') {
                await this.registrarMensaje(this.idRecepcionComercial, this.nuevoMensaje.trim());
            }
        },

        async registrarMensaje(id_recepcioncomercial, mensaje) {
            if (mensaje.trim() === '') return; // No enviar mensajes vacíos

            try {
                if (!this.user || !this.user.nombre) {
                    console.error("Usuario no definido");
                    return;
                }

                const usuario = this.user.nombre;  // Nombre del usuario
                const usuarioId = Number(this.$store.getters.id);  // ID del usuario desde el store

                // Datos a enviar
                const data = {
                    id_recepcioncomercial,
                    mensaje,
                    usuario,  // Usar el nombre del usuario
                    userId: usuarioId,  // Enviar el ID del usuario
                    leido: JSON.stringify([usuarioId])  // Marcar como leído para el usuario que envía el mensaje
                };

                const response = await axios.post('https://apisantiago.cmimbral.cl/registrarMensaje', data);

                if (response.data.message) {
                    this.$bvToast.toast('Mensaje registrado exitosamente', {
                        title: 'Éxito',
                        variant: 'success',
                        solid: true
                    });

                    this.nuevoMensaje = '';  // Limpiar el campo de texto
                    await this.obtenerMensajes(id_recepcioncomercial);  // Actualizar mensajes en el chat
                }
            } catch (error) {
                console.error("Error al registrar el mensaje:", error.response ? error.response.data : error.message);
                this.$bvToast.toast('Error al registrar el mensaje', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                });
            }
        },
    },

    async mounted() {
        await this.obtenerUsuario();
        await this.fetchData();
        await this.verificarMensajesNoLeidos();
    }
};
</script>


<style scoped>
.btn-uniform {
    width: 110px;
    /* Ajusta según sea necesario */
    height: 40px;
    /* Ajusta la altura */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 5px;
    font-size: 14px;
    white-space: nowrap;
    /* Para que el texto no se divida en múltiples líneas */
}

.btn-uniform i {
    margin-right: 5px;
    /* Espacio entre el icono y el texto */
}

.btn-uniform2 {
    width: 80px;
    /* Ajusta según sea necesario */
    height: 40px;
    /* Ajusta la altura */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 5px;
    font-size: 14px;
    white-space: nowrap;
    /* Para que el texto no se divida en múltiples líneas */
}

.btn-uniform2 i {
    margin-right: 5px;
    /* Espacio entre el icono y el texto */
}

.table td button {
    margin: 0 auto;
    /* Centrar botones dentro de las celdas de la tabla */
}

.table-container {
    width: 100%;
    overflow-x: auto;
    /* Permite desplazamiento horizontal si la tabla es más ancha que el contenedor */
}

.table {
    max-width: 1200px;
    /* Ajusta este valor según sea necesario */
    width: 100%;
    /* La tabla ocupará el 100% del ancho del contenedor, hasta el máximo especificado */
}

.navbar {
    background-color: #013b8c;
    color: white;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar-nav {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.nav-item {
    margin-right: 20px;
}

.nav-link {
    color: white;
    text-decoration: none;
}

.user-info {
    display: flex;
    align-items: center;
}

.btn-logout {
    background-color: #ff3e3e;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

/* Content section styles */
.content-section {
    background: #e7e7e7;
    margin: 50px auto;
    padding: 20px;
    width: 90%;
    border-radius: 20px;
}

.section-card {
    background: white;
    padding: 20px;
    margin-bottom: 50px;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.card-header {
    background: #013b8c;
    color: white;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
}

.form-input-group {
    position: relative;
}

.form-input-group .input-group {
    display: flex;
}

.input-group-append {
    margin-left: -1px;
    /* Removes the duplicate border between input and button */
}

.btn-primary {
    background-color: #0056b3;
    border-color: #0056b3;
}

.btn-secondary {
    background-color: #6c757d;
    border-color: #6c757d;
}

.btn-info {
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.btn-success {
    background-color: #28a745;
    border-color: #28a745;
}

.modal-lg {
    max-width: 90%;
}

.modal-body img {
    width: 100%;
    height: auto;
}

.modal-backdrop {
    background-color: rgba(0, 0, 0, 0.8);
}
</style>

<template>
    <div class="main-container">
        <nav class="navbar d-print-none navbar-light d-flex justify-content-center mb-4"
            style="background-color: #003A8C;">
            <div class="container">
                <ul class="navbar-nav">
                    <li class="nav-item active">
                        <div>
                            <b-dropdown id="dropdown-offset" offset="25" text="Opciones" class="m-2">
                                <!-- Visible solo para Santiago y TI -->
                                <b-dropdown-item v-if="userTipo === 'Santiago' || userTipo === 'TI'"
                                    href="/registrarRec">
                                    Registrar Recepción
                                </b-dropdown-item>

                                <!-- Visible solo para Santiago y TI -->
                                <b-dropdown-item v-if="userTipo === 'Santiago' || userTipo === 'TI'" href="/cargarCam">
                                    Cargar Camión Transportes
                                </b-dropdown-item>

                                <!-- Visible solo para Santiago y TI -->
                                <b-dropdown-item v-if="userTipo === 'Adquisiciones' || userTipo === 'TI'"
                                    href="/registrarInterno">
                                    Reg. Recepción Interna
                                </b-dropdown-item>

                                <!-- Visible solo para TI -->
                                <b-dropdown-item
                                    v-if="userTipo === 'TI' || userTipo === 'Recepción' || userTipo === 'Adquisiciones'"
                                    href="/buscarInfo">
                                    Informe Traslados
                                </b-dropdown-item>

                                <!-- Visible solo para Santiago y TI -->
                                <b-dropdown-item
                                    v-if="userTipo === 'Santiago' || userTipo === 'TI' || userTipo === 'Recepción' || userTipo === 'Adquisiciones'"
                                    href="/buscarRecep">
                                    Buscar Guía Recepción
                                </b-dropdown-item>

                                <!-- Visible solo para TI -->
                                <b-dropdown-item
                                    v-if="userTipo === 'TI' || userTipo === 'Recepción' || userTipo === 'Adquisiciones'"
                                    href="/recepcionCC">
                                    Recepción Comercial
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </li>
                </ul>

                <div class="d-flex justify-content-center" id="logo">
                    <img class="img-fluid" src="https://seguimiento.cmimbral.cl/assets/img/logoSeguimiento.png"
                        width="300">
                </div>

                <div class="d-flex">
                    <span class="navbar-text text-white me-3">Usuario: {{ user?.nombre || 'Invitado' }}</span>
                    <button class="btn btn-danger ml-auto" @click="logout">
                        <b-icon icon="power" aria-hidden="true"></b-icon> Cerrar sesión
                    </button>
                </div>
            </div>
        </nav>
        <div class="content-section">
            <div class="section-card">
                <div class="card-header">
                    <h2><strong>Registrar recepción de mercadería</strong></h2>
                </div>
                <div class="form-group col-md-12">
                    <div class="row">
                        <!-- Input with Search Button -->
                        <div class="form-input-group col-md-4">
                            <label><strong>RUT o Nombre proveedor</strong></label>
                            <div class="input-group">
                                <input type="text" v-model="buscar" class="form-control"
                                    placeholder="Ingrese RUT o Nombre del proveedor">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary" type="button" @click="buscarProveedor">
                                        <b-icon icon="search"></b-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="form-input col-md-4">
                            <label>Empresa</label>
                            <input type="text" v-model="nombre" class="form-control" placeholder="Nombre empresa">
                        </div>
                        <div class="form-input col-md-4">
                            <label>Rut de la empresa</label>
                            <input type="text" v-model="rut" @input="formatRut" class="form-control" placeholder="Rut"
                                maxlength="10">
                        </div>
                    </div>
                </div>
            </div>

            <div class="section-card">
                <div class="card-header">
                    <h4><strong>Guía</strong></h4>
                </div>
                <div class="form-group col-md-12">
                    <div class="row">
                        <div class="form-input col-md-6">
                            <label>N° Guía</label>
                            <input type="text" v-model="nDoc" placeholder="Ingrese número de guía">
                        </div>
                        <div class="form-input col-md-6">
                            <label>N° Orden de Compra</label>
                            <input type="text" v-model="ordenCompra" placeholder="Ingrese orden de compra">
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-12">
                    <label><strong>Observación (Opcional)</strong></label>
                    <textarea v-model="observacion" placeholder="Dejar una observación"></textarea>
                </div>
                <div class="form-group col-md-12">
                    <div class="row">
                        <div class="form-group col-md-2"><button @click="limpiarCampos" class="btn-action">Limpiar
                                campos</button></div>
                        <div class="form-group col-md-2">
                            <button @click="registrarRecepcion" class="btn-action1">Registrar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import moment from 'moment';
import { mapState } from "vuex";

export default {
    computed: {
        userTipo() {
            return this.$store.getters.tipo;
        },
    },
    data() {
        return {
            buscar: '',
            rut: '',
            nombre: '',
            nDoc: '',
            ordenCompra: '',
            observacion: '',
            user: null
        }
    },

    methods: {
        formatRut() {
            let rutLimpio = this.rut.replace(/[^0-9Kk]/g, '');
            if (rutLimpio.length > 1) {
                if (rutLimpio.slice(-1).toUpperCase() === 'K') {
                    rutLimpio = rutLimpio.slice(0, -1) + 'K';
                }
                const cuerpo = rutLimpio.slice(0, -1);
                const digitoVerificador = rutLimpio.slice(-1);
                this.rut = `${cuerpo}-${digitoVerificador}`;
            } else {
                this.rut = rutLimpio;
            }
        },

        async logout() {
            try {
                await axios.post('http://localhost:3000/logout', {}, {
                });
                this.$router.push('/');
            } catch (error) {
                console.error("Error al cerrar sesión", error.message);
                Swal.fire({
                    icon: "error",
                    title: "Error al cerrar sesión",
                    text: "Ocurrió un error al intentar cerrar sesión.",
                });
            }
        },

        limpiarCampos() {
            this.buscar = '';
            this.rut = '';
            this.nombre = '';
            this.nDoc = '';
            this.ordenCompra = '';
            this.observacion = '';
        },

        async buscarProveedor() {
            try {
                const response = await axios.get('http://localhost:3000/obtenerProveedores', {
                    params: {
                        buscar: this.buscar // Envía el término de búsqueda al backend
                    },
                });

                if (response.data.length > 0) {
                    const proveedor = response.data[0]; // Toma el primer resultado
                    this.nombre = proveedor.nombre;
                    this.rut = proveedor.rut;
                } else {
                    Swal.fire({
                        icon: 'info',
                        title: 'No encontrado',
                        text: 'No se encontró ningún proveedor con ese RUT o nombre.',
                    });
                }
            } catch (error) {
                console.error('Error al buscar proveedor:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Ocurrió un error al buscar el proveedor.',
                });
            }
        },

        async obtenerUsuario() {
            try {
                const response = await axios.get('http://localhost:3000/usuario', {
                    params: {
                        nombre: this.$store.getters.user,
                        correo: this.$store.getters.correo,
                        tipo: this.$store.getters.tipo,
                        rut: this.$store.getters.rut,
                        id: this.$store.getters.id,
                    }
                });
                this.user = response.data;
                // Si deseas actualizar el store con los datos recibidos:
                this.$store.dispatch('login', response.data);
            } catch (error) {
                console.error("Error al obtener usuario", error.response ? error.response.data : error.message);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Ocurrió un error al intentar obtener los datos del usuario.",
                });
            }
        },

        // De aquí para abajo se vienen cambios para la recepción que no pasa por lo Ovalle
        async registrarRecepcion() {
            try {
                // Paso 1: Buscar o crear proveedor
                const proveedorResponse = await axios.get('http://localhost:3000/obtenerTotalProveedores', {
                    params: {
                        rut: this.rut,
                        nombre: this.nombre
                    },
                });
                console.log('Proveedor encontrado:', proveedorResponse.data);

                let proveedorId;
                if (proveedorResponse.data.length === 0) {
                    // Crear proveedor si no existe
                    const nuevoProveedor = {
                        rut: this.rut,
                        nombre: this.nombre
                    };
                    const createProveedorResponse = await axios.post('http://localhost:3000/creaProv', nuevoProveedor);
                    proveedorId = createProveedorResponse.data.id;
                } else {
                    // Asignar el ID del proveedor existente
                    proveedorId = proveedorResponse.data[0].id;
                }
                console.log('ID del proveedor:', proveedorId);

                // Paso 2: Registrar recepción
                const recepcionData = {
                    folio_prov: this.nDoc,
                    orden: this.ordenCompra,
                    observacion: this.observacion,
                    recepcion: "Comercial"
                };
                console.log('Datos de la recepción:', recepcionData);

                const recepcionResponse = await axios.post('http://localhost:3000/recepcionMercaderiaInterna', recepcionData);
                if (!recepcionResponse.data.id) {
                    throw new Error('No se pudo obtener el ID de la recepción registrada.');
                }
                console.log('Respuesta de la recepción:', recepcionResponse.data);

                this.limpiarCampos();

            } catch (error) {
                console.error('Error al registrar la recepción:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: `Ocurrió un error al registrar la recepción en el paso del cliente: ${error.message}. Por favor, inténtalo de nuevo.`,
                });
            }
        }
    },
    mounted() {
        this.obtenerUsuario();
    }
}
</script>

<style scoped>
/* Styles applied directly to the Vue component */

/* Navigation bar styles */
.navbar {
    background-color: #013b8c;
    color: white;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar-nav {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.nav-item {
    margin-right: 20px;
}

.nav-link {
    color: white;
    text-decoration: none;
}

.user-info {
    display: flex;
    align-items: center;
}

.btn-logout {
    background-color: #ff3e3e;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

/* Content section styles */
.content-section {
    background: #e7e7e7;
    margin: 50px auto;
    padding: 20px;
    width: 90%;
    border-radius: 20px;
}

.section-card {
    background: white;
    padding: 20px;
    margin-bottom: 50px;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.card-header {
    background: #013b8c;
    color: white;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
}

.form-input-group {
    position: relative;
}

.form-input-group .input-group {
    display: flex;
}

.input-group-append {
    margin-left: -1px;
    /* Removes the duplicate border between input and button */
}

.btn-outline-secondary {
    border-color: #ccc;
    color: #013b8c;
}

.btn-outline-secondary:hover {
    background-color: #013b8c;
    color: white;
}

.form-input {
    margin-bottom: 15px;
}

.form-input label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-input input,
textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

textarea {
    resize: none;
}

.btn-upload,
.btn-action {
    background-color: #013b8c;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px;
}

.btn-action1 {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px;
}

.btn-action1:hover {
    background-color: #218838;
}
</style>
